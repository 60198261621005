import React, { useState } from "react";
import { message, Tag as ElementTag } from "antd";

import Icon from "../common/Icon";
import Avatar from "../common/Avatar";
import api from "../../services/api";
import { goalsStatus } from "../common/text/Status";
import InputField from "../common/forms/InputField";
import Button from "../common/Button";
import RemoveAlert from "../common/RemoveAlert";
import UserPopover from "../priorities/UserPopover";
import Tag from "../tags/Tag";
import PriorityBody from "./PriorityBody";
import ActionsMenu from "../common/ActionsMenu";
import TrailingIcons from "../common/TrailingIcons";
import TagSelector from "../tags/TagSelector";
import SelectUsers from "../priorities/SelectUsers";

export default function PriorityItem({
  index,
  onUpdate,
  data,
  mutatePriorityItems,
  reduced = false,
}) {
  const [editing, setEditing] = useState(false);
  const [selectingTag, setSelectingTag] = useState(false);
  const [selectingUser, setSelectingUser] = useState(false);

  const handlePatch = async (values) => {
    try {
      const { data: res } = await api.patch(
        `/weekly_priorities/${data.id}/`,
        values
      );
      onUpdate(res);
    } catch {
      message.error("Parece que houve um erro, tente novamente mais tarde!");
    }
  };

  const handleBlur = async (e) => {
    if (selectingTag || selectingUser) return;
    if (e.target.value.length > 0 && e.target.value !== data.title) {
      await handlePatch({
        title: e.target.value?.replace("#", "")?.replace("@", ""),
      });
    }
    setEditing(false);
  };

  const handleDelete = () => {
    RemoveAlert({
      customTitle: "Você tem certeza que deseja remover este foco da semana?",
      continueCallback: async () => {
        try {
          await api.delete(`/weekly_priorities/${data.id}/`);
          onUpdate(data.id, true);
        } catch {
          message.error(
            "Parece que houve um erro, tente novamente mais tarde!"
          );
        }
      },
    });
  };

  const handleTrackKeys = (e) => {
    // if enter pressed
    if (e.keyCode === 13) e.target.blur();

    // if # pressed
    if (e.keyCode === 51) {
      e?.preventDefault();
      setSelectingTag(true);
      setTimeout(() => {
        document.getElementById("#tag-selector").focus();
      }, 300);
    }

    // if @ pressed
    if (e.keyCode === 50) {
      setSelectingUser(true);
      setTimeout(() => {
        document.getElementById("user-selector").focus();
      }, 300);
    }
  };

  const handleSelectTags = (value) => {
    handlePatch({ tags: value?.tags });
    setSelectingTag(false);
    document.getElementById("priority-input")?.focus();
  };

  const handleSelectUsers = (value) => {
    handlePatch({ assigned_to: value });
    setSelectingUser(false);
    document.getElementById("priority-input")?.focus();
  };

  return (
    <div className="d:f a-i:c pos:relative">
      <div className="m-r:1">
        <Avatar className="bg-primary text-white">{index}</Avatar>
      </div>
      {editing && selectingUser && (
        <div
          className="pos:a"
          style={{
            bottom: 30,
            left: 60,
            right: 105,
            zIndex: 10,
          }}
        >
          <SelectUsers
            onChange={handleSelectUsers}
            value={data?.assigned_to?.map((v) => v?.id)}
          />
        </div>
      )}
      {editing && selectingTag && (
        <div
          className="pos:a"
          style={{
            bottom: 40,
            left: 60,
            right: 105,
            zIndex: 10,
          }}
        >
          <TagSelector onChange={handleSelectTags} />
        </div>
      )}
      {editing ? (
        <InputField
          defaultValue={data.title}
          onBlur={handleBlur}
          autoFocus
          onKeyUp={handleTrackKeys}
          id="priority-input"
        />
      ) : (
        <PriorityBody className="pos:r">
          <h6
            className="d:i-b f-w:400 f:1 cur:p"
            onClick={() => setEditing(true)}
          >
            {data.title}
          </h6>
          {!editing && data?.tags && (
            <div className="d:i-b m-l:.7">
              {data?.tags?.map((tag) => (
                <Tag color={tag.color} text={tag.name} />
              ))}
            </div>
          )}
          {!editing && data?.status && (
            <ElementTag
              color={goalsStatus[data.status].color}
              className="text-white m-l:1"
            >
              {goalsStatus[data.status].label}
            </ElementTag>
          )}
          {!editing && (
            <TrailingIcons className="d:i-b" shortcuts={data?.shortcuts} />
          )}
          {!editing && (
            <div className="pos:a right:.8 abs-a-y">
              {!reduced && (
                <UserPopover
                  objUsers={data.assigned_to}
                  onUpdate={({ users }) => handlePatch({ assigned_to: users })}
                />
              )}
              <ActionsMenu
                reduced={reduced}
                onDelete={handleDelete}
                onUpdate={handlePatch}
                data={data}
                dataType="WEEKLY_PRIORITY"
                mutate={mutatePriorityItems}
              />
            </div>
          )}
        </PriorityBody>
      )}

      {!editing && !reduced && (
        <div className="m-l:.5">
          {["IN_PROGRESS", "SUCCESS", "FAILED"].map(
            (k) =>
              data.status !== k && (
                <Button
                  className="m-l:.5"
                  type="white"
                  shape="circle"
                  icon={
                    <Icon type={k} style={{ color: goalsStatus[k].color }} />
                  }
                  onClick={() => handlePatch({ status: k })}
                />
              )
          )}
        </div>
      )}
    </div>
  );
}
