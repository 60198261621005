import React from "react";
import { Avatar, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { Link } from "react-router-dom";

import Card from "../common/Card";
import Icon from "../common/Icon";

import PriorityBody from "./PriorityBody";
import Tooltip from "../common/Tooltip";
import TrailingIcons from "../common/TrailingIcons";
import bullseye from "../../svgs/bullseye.svg";

function Priority({ data, index }) {
  return (
    <div className="d:f a-i:c">
      <div className="m-r:1">
        <Avatar className="bg-primary text-white">{index + 1}</Avatar>
      </div>
      <PriorityBody>
        <div className="d:i-b m-r:.6">
          {data?.tags?.map((tag) => (
            <Tag
              color={tag.color}
              children={tag.name}
              key={`tag=${tag.name}`}
            />
          ))}
        </div>
        {data.title}
        <TrailingIcons className="d:i-b m-x:.7" shortcuts={data?.shortcuts} />
        {data?.assigned_to && (
          <div className="d:i-b">
            <Space>
              {data?.assigned_to?.map((user) => (
                <Tooltip
                  title={
                    user.name ||
                    user.full_name ||
                    `${user.first_name} ${user.last_name}`
                  }
                  key={`assigned-user-${user.id}`}
                >
                  <Avatar src={user.profile_image} size={30} />
                </Tooltip>
              ))}
            </Space>
          </div>
        )}
      </PriorityBody>
    </div>
  );
}

function Priorities({ data, date }) {
  return (
    <div>
      {date && <h6>Semana {moment(date).get("week")}</h6>}
      {data && data.length > 0 ? (
        <ul>
          {data.map((item, index) => (
            <li key={item.id} className="m-b:.8">
              <Priority data={item} index={index} />
            </li>
          ))}
        </ul>
      ) : (
        <div className="p-y:2 t-a:c text-gray">
          <Icon type="empty" className="f:4" />
          <p className="m-t:.6">Não há focos</p>
        </div>
      )}
    </div>
  );
}

// Lista de focos dividos em secoes (Organizacao, time e pessoal)
export default function PrioritiesList({ data, teams }) {
  const sections = [];
  const teamSections = {};
  const { t } = useTranslation();
  // Divide focos de times
  data
    .filter(({ type }) => type === "TEAM")
    .forEach((item) => {
      if (!teamSections[item.team]) teamSections[item.team] = [];
      teamSections[item.team].push(item);
    });
  // Focos da organizacao
  sections.push({
    title: "Organização",
    items: data.filter(({ type }) => type === "COMPANY"),
  });
  // Inclui focos de times com nomes dos times na lista de secoes
  for (let teamId in teamSections) {
    teamId = parseInt(teamId);
    sections.push({
      title: teams?.find(({ id }) => id === teamId)?.name,
      items: teamSections[teamId],
    });
  }
  // Inclui focos pessoais
  sections.push({
    title: "Meus focos",
    items: data.filter(({ type }) => type === "MEMBER"),
  });

  return (
    <div>
      {data?.length > 0 ? (
        <ul>
          {sections.map(
            (section, i) =>
              section.items.length > 0 && (
                <li key={i}>
                  <h6 className="t-t:u text-primary">{section.title}</h6>
                  <Priorities data={section.items} />
                </li>
              )
          )}
        </ul>
      ) : (
        <div className="d:f flx-d:c m-b:1 a-i:c">
          <span className="f:10">
            <img src={bullseye} />
          </span>
          <span className="text-darkviolet-sec">
            {t("weeklypriorities-empty.label")}
          </span>
          <Link className="text-primary" to={`/focos-da-semana/criar`}>
            {t("weeklypriorities-create")}
          </Link>
        </div>
      )}
    </div>
  );
}
